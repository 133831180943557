const initialState = {
  videoStatus: true,
  audioStatus: true,
  videoDevice: "",
  audioDevice: "",
  openSettings: false,
  chatOpened: false,
};

const videoSettings = (state = initialState, action: any) => {
  switch (action.type) {
    case "DISABLE_OR_ENABLE_VIDEO":
      return Object.assign({}, state, {
        videoStatus: action.videoStatus,
      });
    case "DISABLE_OR_ENABLE_AUDIO":
      return Object.assign({}, state, {
        audioStatus: action.audioStatus,
      });
    case "SET_VIDEO_DEVICE":
      return Object.assign({}, state, {
        videoDevice: action.videoDevice,
      });
    case "SET_AUDIO_DEVICE":
      return Object.assign({}, state, {
        audioDevice: action.audioDevice,
      });
    case "OPEN_SETTINGS":
      return Object.assign({}, state, {
        openSettings: action.opened,
      });
    case "OPEN_CHAT":
      return Object.assign({}, state, {
        chatOpened: action.chatOpened,
      });
    default:
      return state;
  }
};

export default videoSettings;
