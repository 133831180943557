import { Action } from "redux";

const initialState = {
  hasEnabledPermissions: false,
  devices: [],
  videoDevices: [],
  audioDevices: [],
  localVideoTrack: null,
  localAudioTrack: null,
  officeName: "",
  officePk: null,
  officeSlug: null,
  isFetchingOffice: true,
  validatingOfficeError: "",
  thread: null,
};

const mainReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_DEVICES":
      return Object.assign({}, state, {
        devices: action.devices,
      });
    case "SET_VIDEO_DEVICES":
      return Object.assign({}, state, {
        videoDevices: action.devices,
      });
    case "SET_AUDIO_DEVICES":
      return Object.assign({}, state, {
        audioDevices: action.devices,
      });
    case "SET_LOCAL_VIDEO_TRACK":
      return Object.assign({}, state, {
        localVideoTrack: action.localVideoTrack,
      });
    case "SET_LOCAL_AUDIO_TRACK":
      return Object.assign({}, state, {
        localAudioTrack: action.localAudioTrack,
      });
    case "SET_ENABLED_PERMISSIONS":
      return Object.assign({}, state, {
        hasEnabledPermissions: action.enabledPermissions,
      });
    case "SET_ENABLED_PERMISSIONS":
      return Object.assign({}, state, {
        hasEnabledPermissions: action.enabledPermissions,
      });
    case "REQUEST_VALIDATING_OFFICE":
      return Object.assign({}, state, {
        isFetchingOffice: true,
        validatingOfficeError: "",
      });
    case "RECEIVE_VALIDATING_OFFICE":
      return Object.assign({}, state, {
        isFetchingOffice: false,
        officeName: action.officeName,
        officePk: action.officePk,
        officeSlug: action.slug,
      });
    case "ERROR_VALIDATING_OFFICE":
      return Object.assign({}, state, {
        isFetchingOffice: false,
        validatingOfficeError: "The link you entered isn't valid.",
      });
    case "RECEIVE_GET_WAITING_ROOM_THREAD":
      return Object.assign({}, state, {
        thread: action.waitingRoomThread,
      });
    default:
      return state;
  }
};

export default mainReducer;
