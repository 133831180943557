import { Dispatch } from "react";
import axios, { AxiosRequestConfig } from "axios";
import { createSocket } from "dgram";
import ReconnectingWebSocket from "reconnecting-websocket";

function string_to_slug(str: string) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

function setSocketStatus(status: string) {
  return {
    type: "SET_SOCKET_STATUS",
    socketStatus: status,
  };
}

function setSocket(socket: ReconnectingWebSocket) {
  return {
    type: "SET_SOCKET",
    socket,
  };
}

function requestSubmittedForm() {
  return {
    type: "REQUEST_SUBMITTING_FORM",
    isSubmittingForm: true,
    submittingMessage: "Submitting info.",
  };
}

function receiveSubmittedForm(data: object) {
  return {
    type: "RECEIVE_SUBMITTING_FORM",
    userDetails: data,
    submittingMessage: "Submitted information successfully!",
  };
}

function errorSubmittedForm() {
  return {
    type: "ERROR_SUBMITTING_FORM",
    isSubmittingForm: false,
    submittingMessage: "There was an error submitting your information.",
  };
}

function receiveVideoRoom(videoRoomName: string) {
  return {
    type: "RECEIVE_VIDEO_ROOM",
    videoRoomName,
  };
}

function requestSubmitBrowserDetails() {
  return {
    type: "REQUEST_SUBMIT_BROWSER_DETAILS",
    submittingMessage: "Connecting to the virtual office...",
  };
}

function receiveSubmitBrowserDetails() {
  return {
    type: "RECEIVE_SUBMIT_BROWSER_DETAILS",
    isSubmittingForm: false,
    submittingMessage: "Connected to the virtual office!",
  };
}

function errorSubmitBrowserDetails() {
  return {
    type: "ERROR_SUBMIT_BROWSER_DETAILS",
    isSubmittingForm: false,
    submittingMessage:
      "We couldn't connect you to the virtual office... Please refresh the page and try again.",
  };
}

function getRandomArbitrary(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

type SubmitDetails = {
  emailAddress: string;
  fullName: string;
  officeSlug: string;
};

export function submittingDetails(
  guestInfo: SubmitDetails,
  browserData: object,
  officePk: number
) {
  const nameSlug = string_to_slug(guestInfo.fullName);
  let config: AxiosRequestConfig = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/appointments/submit-guest-details/`,
    data: {
      email: guestInfo.emailAddress,
      name: guestInfo.fullName,
      unique_code: `${nameSlug}${getRandomArbitrary(1, 150)}`,
      office: officePk,
    },
  };

  return (dispatch: Dispatch<any>) => {
    dispatch(requestSubmittedForm());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveSubmittedForm(data));
        dispatch(
          submitBrowserDetails(
            data.pk,
            browserData,
            guestInfo,
            data.unique_code,
            officePk,
            guestInfo.officeSlug
          )
        );
      })
      .catch((err) => {
        dispatch(errorSubmittedForm());
        console.log(err);
      });
  };
}

export function submitBrowserDetails(
  guestUserId: string,
  browserData: any,
  guestInfo: SubmitDetails,
  uniqueCode: string,
  officePk: number,
  officeSlug: string
) {
  let config: AxiosRequestConfig = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/appointments/submit-browser-details/`,
    data: {
      device: browserData.device,
      operating_system: browserData.operatingSystem,
      browser: browserData.browser,
      guest_patient: guestUserId,
    },
  };

  return (dispatch: Dispatch<any>) => {
    dispatch(requestSubmitBrowserDetails());
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(receiveSubmitBrowserDetails());
        dispatch(
          createTriage(guestUserId, officePk, guestInfo, uniqueCode, officeSlug)
        );
      })
      .catch((err) => dispatch(errorSubmitBrowserDetails()));
  };
}

export function createTriage(
  guestUserId: string,
  officePk: number,
  guestInfo: SubmitDetails,
  uniqueCode: string,
  officeSlug: string
) {
  let config: AxiosRequestConfig = {
    method: "post",
    url: `${process.env.REACT_APP_DEV_API}/appointments/triage/`,
    data: {
      guest_patient: guestUserId,
      office: officePk,
      patient_notes: "N/A",
    },
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((data) => {
        dispatch(getVideoRoom(guestInfo, uniqueCode, guestUserId, officePk));
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
}

export function getVideoRoom(
  data: SubmitDetails,
  uniqueCode: string,
  guestUserId: string,
  officePk: number
) {
  let config: AxiosRequestConfig = {
    method: "get",
    url: `${process.env.REACT_APP_DEV_API}/appointments/guest-video-chat/${guestUserId}/${officePk}/`,
  };

  return (dispatch: Dispatch<any>) => {
    return axios(config)
      .then((res) => res.data)
      .then((chatData) => {
        dispatch(receiveVideoRoom(chatData[0].chat_name));
        dispatch(createSocketConnection(data, uniqueCode, guestUserId));
      });
  };
}

export function createSocketConnection(
  data: SubmitDetails,
  uniqueCode: string,
  guestUserId: string
) {
  return (dispatch: Dispatch<any>) => {
    const socket = new ReconnectingWebSocket(
      `${process.env.REACT_APP_DEV_SOCKET}/${data.officeSlug}/${uniqueCode}/`
    );

    socket.onopen = (e: any) => {
      dispatch(setSocket(socket));
      dispatch(setSocketStatus("Connected"));
      socket.send(
        JSON.stringify({
          type: "JOIN_WAITING_ROOM",
          guest_patient: guestUserId,
          office: data.officeSlug,
        })
      );
    };

    socket.onerror = (e: any) => {
      dispatch(setSocketStatus("Error"));
    };

    socket.onclose = (e: any) => {
      dispatch(setSocketStatus("Disconnected"));
    };
  };
}
