import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import { Prompt } from "react-router";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Avatar from "react-avatar";
import { VideoCall } from "../components/VideoCall/VideoCall";
import { VideoSettingsPanel } from "../components/VideoSettings/VideoSettingsPanel";
import { attachTracks } from "../utils/trackFunctions";
import CameraDisabled from "../assets/images/cameradisabled.png";
import { getWaitingRoomThread } from "../actions/meetingActions";
import { WaitingRoomChat } from "../components/WaitingRoomChat";
import { LeavingPrompt } from "../components/LeavingPrompt";

export const WaitingRoom = (props: any) => {
  const dispatch = useDispatch();
  const videoContainer = useRef(null);
  const history = useHistory();
  const [openPrompt, setOpenPrompt] = useState(false);
  const localVideoTrack = useSelector(
    (state: RootStateOrAny) => state.mainReducer.localVideoTrack
  );

  const localAudioTrack = useSelector(
    (state: RootStateOrAny) => state.mainReducer.localAudioTrack
  );

  const videoStatus = useSelector(
    (state: RootStateOrAny) => state.mainReducer.videoStatus
  );
  const officeName = useSelector(
    (state: RootStateOrAny) => state.mainReducer.officeName
  );
  const officeSlug = useSelector(
    (state: RootStateOrAny) => state.mainReducer.officeSlug
  );
  const officePk = useSelector(
    (state: RootStateOrAny) => state.mainReducer.officePk
  );

  const socket = useSelector(
    (state: RootStateOrAny) => state.socketConnection.socket
  );

  const userId = useSelector(
    (state: RootStateOrAny) => state.socketConnection.userDetails
  );
  const chatOpened = useSelector(
    (state: RootStateOrAny) => state.videoSettings.chatOpened
  );

  function listenMessage(e: any) {
    const response_data = JSON.parse(e.data);
    if (response_data.type === "admin_join_active_call") {
      history.push(`/${officeSlug}/lobby`);
    } else {
      return;
    }
  }
  useEffect(() => {
    // Check to see if this is a page refresh
    if (!socket) {
      // alert(props.history.location.pathname.substr(1));
      history.push(`/${props.history.location.pathname.split("/")[1]}`);
    }
  }, []);

  useEffect(() => {
    attachTracks([localVideoTrack], videoContainer.current);
  }, [localVideoTrack]);

  useEffect(() => {
    attachTracks([localAudioTrack], videoContainer.current);
  }, [localAudioTrack]);

  useEffect(() => {
    if (socket) {
      socket.addEventListener("message", listenMessage);
      return () => socket.removeEventListener("message", listenMessage);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(getWaitingRoomThread(userId.pk, officePk));
    }
  }, []);

  // useEffect(() => {
  //   return () => {
  //     history.listen((location) => {
  //       console.log(history.action);
  //       if (history.action === "POP") {
  //         alert("hi");
  //       }
  //     });
  //   };
  // }, [history]);

  return (
    <React.Fragment>
      <Header />
      <React.Fragment>
        <Prompt
          when={true}
          message={(location, action) => {
            if (action === "POP") {
              return "Exiting this screen will remove you from the waiting line. Are you sure you want to leave?";
            } else {
              return true;
            }
          }}
        />
      </React.Fragment>

      <section className="main-bg-sectoin meet-form-main d-flex align-items-center waiting-room">
        <div className="d-md-none">{chatOpened ? <WaitingRoomChat /> : ""}</div>
        <div className="container">
          <div className="doctor-information-container">
            <div className="doctor-information">
              <Avatar size={"104"} name={officeName} />
              <h2>{officeName}</h2>
            </div>

            <div className="video-container" ref={videoContainer}>
              {videoStatus == "enabled" ? (
                ""
              ) : (
                <img src={CameraDisabled} alt="" className="disabled-camera" />
              )}
            </div>
            <p>YOUR CALL WILL BEGIN SHORTLY</p>
          </div>
        </div>
        <VideoSettingsPanel waitingRoom={true} />
      </section>
    </React.Fragment>
  );
};
