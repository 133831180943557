import React from "react";
import Avatar from "react-avatar";
import moment from "moment";

export const ChatMessage = (props: any) => {
  return props.message.sent_by_guest ? (
    <div className="chat-text right">
      <div className="chat-info">
        <h3>{props.message.sent_by_guest} </h3>
        <span className="chat-user">
          <Avatar
            name={`${props.message.sent_by_guest}`}
            size={"24"}
            round={true}
          />
          {/* <img src="images/user.png" alt="" /> */}
        </span>
      </div>
      <div className="chat-box">
        <p>{props.message.message}</p>
        <span className="chat-time">
          {moment(props.message.datetime).format("YYYY-MM-DD HH:mm")}
        </span>
      </div>
    </div>
  ) : (
    <div className="chat-text left">
      <div className="chat-info">
        <h3>
          {props.message.sent_by.admin_profile.first_name}{" "}
          {props.message.sent_by.admin_profile.last_name}
        </h3>
        <span className="chat-user">
          <Avatar
            name={`${props.message.sent_by.admin_profile.first_name} ${props.message.sent_by.admin_profile.last_name}`}
            size={"24"}
            round={true}
          />
        </span>
      </div>
      <div className="chat-box">
        <p>{props.message.message}</p>
        <span className="chat-time">
          {moment(props.message.datetime).format("YYYY-MM-DD HH:mm")}
        </span>
      </div>
    </div>
  );
};
