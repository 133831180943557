import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  browserName,
  browserVersion,
  osName,
  deviceType,
} from "react-device-detect";
import { validateOfficeSlug } from "../../actions/meetingActions";
import { BounceLoader } from "react-spinners";
import { attachTracks } from "../../utils/trackFunctions";
import {
  createSocketConnection,
  submittingDetails,
} from "../../actions/socketActions";

type FormValues = {
  emailAddress: string;
  fullName: string;
};

export const HomeRegister = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { handleSubmit, register, control, watch, errors } = useForm();

  const socketStatus = useSelector(
    (state: RootStateOrAny) => state.socketConnection.socketStatus
  );

  const socket = useSelector(
    (state: RootStateOrAny) => state.socketConnection.socket
  );

  function listenMessage(e: any) {
    const response_data = JSON.parse(e.data);
    if (response_data.type === "confirm_waiting") {
      history.push(
        `/${props.history.location.pathname.substr(1)}/waiting-room`
      );
    } else {
      return;
    }
  }

  useEffect(() => {
    console.log(props.history.location.pathname.substr(1));
    dispatch(validateOfficeSlug(props.history.location.pathname.split("/")[1]));
  }, []);

  useEffect(() => {
    if (socketStatus == "Connected") {
      socket.addEventListener("message", listenMessage);
      return () => socket.removeEventListener("message", listenMessage);
    }
  }, [socketStatus]);

  const videoPreview = useRef(null);

  const isFetchingOffice = useSelector(
    (state: RootStateOrAny) => state.mainReducer.isFetchingOffice
  );
  const officeName = useSelector(
    (state: RootStateOrAny) => state.mainReducer.officeName
  );

  const officePk = useSelector(
    (state: RootStateOrAny) => state.mainReducer.officePk
  );

  const localVideoTrack = useSelector(
    (state: RootStateOrAny) => state.mainReducer.localVideoTrack
  );

  const localAudioTrack = useSelector(
    (state: RootStateOrAny) => state.mainReducer.localAudioTrack
  );
  const isSubmittingForm = useSelector(
    (state: RootStateOrAny) => state.socketConnection.isSubmittingForm
  );

  const onSubmit: SubmitHandler<any> = (data) => {
    data.officeSlug = props.history.location.pathname.substr(1);
    dispatch(
      submittingDetails(
        data,
        {
          browser: `${browserName} ${browserVersion}`,
          device: deviceType,
          operatingSystem: osName,
        },
        officePk
      )
    );
    // dispatch(createSocketConnection(data));
  };

  useEffect(() => {
    if (localVideoTrack) {
      attachTracks([localVideoTrack, localAudioTrack], videoPreview.current);
    }
  }, [localVideoTrack]);

  return (
    <div className="meet-form white-box">
      {!isFetchingOffice && officeName ? (
        <React.Fragment>
          <h3>You are joining {officeName}</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-label-group">
              <input
                type="text"
                id="inputEmail"
                className="form-control"
                placeholder="Email Address"
                name="emailAddress"
                ref={register({ required: true })}
                required
              />
              <label htmlFor="inputEmail">Email Address</label>
            </div>
            <div className="form-label-group">
              <input
                type="text"
                id="full-name"
                className="form-control"
                placeholder="Full Name"
                required
                name="fullName"
                ref={register({ required: true })}
              />
              <label htmlFor="full-name">Full Name</label>
            </div>
            <div className="fild-row"></div>
            {!isSubmittingForm ? (
              <input
                type="submit"
                className="primary-btn"
                value="Join Waiting Room"
              >
                {/* Request to Join */}
              </input>
            ) : (
              <div style={{ margin: "0 auto" }}>
                <BounceLoader color={"#052D64"} size={58} />
              </div>
            )}
          </form>
        </React.Fragment>
      ) : !officeName && !isFetchingOffice ? (
        <React.Fragment>
          <h3>Invalid Link</h3>
          <p>
            The link you inserted isn't valid. If you need further assistance,
            please contact{" "}
            <a href="mailto:info@getcorigan.ca">info@getcorigan.ca</a>.
          </p>
        </React.Fragment>
      ) : (
        <BounceLoader color={"#052D64"} size={30} />
      )}
      <div id="video-preview" ref={videoPreview}></div>
    </div>
  );
};
