import { combineReducers } from "redux";
import mainReducer from "./mainReducer";
import videoSettings from "./videoSettings";
import socketConnection from "./socketConnection";
import videoCallReducer from "./videoCallReducer";

const allReducers = combineReducers({
  mainReducer,
  videoSettings,
  socketConnection,
  videoCallReducer,
});

export default allReducers;
