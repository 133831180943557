import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
// @ts-ignore
import ScrollToBottom from "react-scroll-to-bottom";
import { BounceLoader } from "react-spinners";
import { ChatMessage } from "./WaitingRoomChat/ChatMessage";
import ChatX from "../assets/images/chat-x.png";
import { setOpenChat } from "../actions/videoSettings";
import { getWaitingRoomThread } from "../actions/meetingActions";

export const WaitingRoomChat = () => {
  const textMessage = useRef<any>(null);
  const dispatch = useDispatch();
  const thread = useSelector(
    (state: RootStateOrAny) => state.mainReducer.thread
  );
  const officePk = useSelector(
    (state: RootStateOrAny) => state.mainReducer.officePk
  );
  const officeSlug = useSelector(
    (state: RootStateOrAny) => state.mainReducer.officeSlug
  );

  const socket = useSelector(
    (state: RootStateOrAny) => state.socketConnection.socket
  );

  const userFullName = useSelector(
    (state: RootStateOrAny) => state.socketConnection.userDetails.name
  );

  const userPk = useSelector(
    (state: RootStateOrAny) => state.socketConnection.userDetails.pk
  );

  function listenMessage(e: any) {
    const response_data = JSON.parse(e.data);
    if (response_data.type === "patient_ping_chat") {
      dispatch(getWaitingRoomThread(userPk, officePk));
    } else {
      return;
    }
  }

  useEffect(() => {
    if (socket) {
      socket.addEventListener("message", listenMessage);
      return () => {
        socket.removeEventListener("message", listenMessage);
      };
    }
  }, [socket]);

  return (
    <div className="office-chat">
      {thread ? (
        <div className="office-chat-container">
          <div className="office-title">
            <h1>Office Chat</h1>
            <img
              src={ChatX}
              alt=""
              onClick={(e) => {
                e.preventDefault();
                dispatch(setOpenChat(false));
              }}
            />
          </div>
          <div className="chat-content">
            <ScrollToBottom
              className="scrolling"
              scrollViewClassName="chat-content-container"
            >
              {thread.waiting_room_thread_messages.map((message: any) => (
                <ChatMessage message={message} />
              ))}
            </ScrollToBottom>
          </div>
          <div className="type-msg">
            <form
              className="form-input"
              onSubmit={(e) => {
                e.preventDefault();
                socket.send(
                  JSON.stringify({
                    type: "GUEST_ADD_MESSAGE_TO_WAITING_ROOM_THREAD",
                    message: textMessage.current.value,
                    userId: userFullName,
                    threadId: thread.pk,
                    office: officeSlug,
                  })
                );
                textMessage.current.value = "";
              }}
            >
              <input
                type="text"
                placeholder="Type your message here…"
                className="common-input"
                ref={textMessage}
              />
              <input type="submit" className="primary-btn" value="Send" />
            </form>
          </div>
        </div>
      ) : (
        <BounceLoader />
      )}
    </div>
  );
};
