import React from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  setVideoDisabled,
  setVideoEnabled,
  setAudioEnabled,
  setAudioDisabled,
  setOpenSettings,
  setOpenChat,
} from "../../actions/videoSettings";
import { VideoSettings } from "./VideoSettings";
import { WaitingRoomChat } from "../WaitingRoomChat";

type VideoSettingsProps = {
  waitingRoom?: boolean;
};

export const VideoSettingsPanel = (props: VideoSettingsProps) => {
  const dispatch = useDispatch();

  const videoStatus = useSelector(
    (state: RootStateOrAny) => state.videoSettings.videoStatus
  );
  const audioStatus = useSelector(
    (state: RootStateOrAny) => state.videoSettings.audioStatus
  );

  const localVideoTrack = useSelector(
    (state: RootStateOrAny) => state.mainReducer.localVideoTrack
  );

  const localAudioTrack = useSelector(
    (state: RootStateOrAny) => state.mainReducer.localAudioTrack
  );

  const audioDevice = useSelector(
    (state: RootStateOrAny) => state.videoSettings.audioDevice
  );

  const videoDevice = useSelector(
    (state: RootStateOrAny) => state.videoSettings.videoDevice
  );

  const openSettings = useSelector(
    (state: RootStateOrAny) => state.videoSettings.openSettings
  );

  const chatOpened = useSelector(
    (state: RootStateOrAny) => state.videoSettings.chatOpened
  );

  return (
    <div className="call-options-main">
      <ul>
        <li>
          {!videoStatus ? (
            <a
              href="#"
              title=""
              className="video-mute video-m"
              onClick={(e) => {
                e.preventDefault();
                dispatch(setVideoEnabled(videoDevice));
              }}
            ></a>
          ) : (
            <a
              href="#"
              title=""
              className="video-unmute video-m"
              onClick={(e) => {
                e.preventDefault();
                dispatch(setVideoDisabled([localVideoTrack]));
              }}
            ></a>
          )}
        </li>
        <li>
          {!audioStatus ? (
            <a
              href="#"
              title=""
              className="speaker-mute speaker"
              onClick={(e) => {
                e.preventDefault();
                dispatch(setAudioEnabled(audioDevice));
              }}
            ></a>
          ) : (
            <a
              href="#"
              title=""
              className="speaker-unmute speaker"
              onClick={(e) => {
                e.preventDefault();
                dispatch(setAudioDisabled([localAudioTrack]));
              }}
            ></a>
          )}
        </li>
        <li className="d-md-none">
          <div className="call-settings">
            {openSettings ? <VideoSettings /> : ""}
            <a
              href="#"
              title=""
              className="setting"
              onClick={(e) => {
                e.preventDefault();
                dispatch(setOpenSettings(!openSettings));
              }}
            ></a>

            <div className="setting-info">Joining call with audio</div>
          </div>
        </li>
        <li className="d-md-none">
          <div className="call-settings">
            {props.waitingRoom ? (
              !chatOpened ? (
                <a
                  href="#"
                  title=""
                  className="chat"
                  style={{ float: "right" }}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setOpenChat(!chatOpened));
                  }}
                ></a>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            <div className="setting-info">Joining call with audio</div>
          </div>
        </li>
      </ul>
      <div className="call-settings d-none d-md-block">
        {openSettings ? <VideoSettings /> : ""}
        <a
          href="#"
          title=""
          className="setting"
          style={{ float: "right" }}
          onClick={(e) => {
            e.preventDefault();
            console.log(openSettings);
            dispatch(setOpenSettings(!openSettings));
          }}
        ></a>
        {chatOpened ? <WaitingRoomChat /> : ""}
        {props.waitingRoom ? (
          !chatOpened ? (
            <a
              href="#"
              title=""
              className="chat"
              style={{ float: "right" }}
              onClick={(e) => {
                e.preventDefault();
                dispatch(setOpenChat(!chatOpened));
              }}
            ></a>
          ) : (
            ""
          )
        ) : (
          ""
        )}
        <div className="setting-info">Joining call with audio</div>
      </div>
    </div>
  );
};
