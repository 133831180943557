import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  connectToRoom,
  participantConnected,
  participantDisconnected,
  setDominantSpeaker,
} from "../../actions/videoCallActions";
import { RemoteParticipant } from "twilio-video";
import { VideoCallRight } from "./VideoCallRight";
import { VideoSettings } from "../VideoSettings/VideoSettings";
import { setOpenSettings } from "../../actions/videoSettings";
import { DominantSpeaker } from "./Participants/DominantSpeaker";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export const VideoCall = (props: any) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const room = useSelector(
    (state: RootStateOrAny) => state.videoCallReducer.room
  );
  const dominantSpeaker = useSelector(
    (state: RootStateOrAny) => state.videoCallReducer.dominantSpeaker
  );

  const localVideoTrack = useSelector(
    (state: RootStateOrAny) => state.mainReducer.localVideoTrack
  );
  const videoRoomName = useSelector(
    (state: RootStateOrAny) => state.socketConnection.videoRoomName
  );
  const socket = useSelector(
    (state: RootStateOrAny) => state.socketConnection.socket
  );

  const localAudioTrack = useSelector(
    (state: RootStateOrAny) => state.mainReducer.localAudioTrack
  );

  const openSettings = useSelector(
    (state: RootStateOrAny) => state.videoSettings.openSettings
  );

  const history = useHistory();

  useEffect(() => {
    dispatch(
      connectToRoom(
        props.videoToken,
        videoRoomName,
        localVideoTrack,
        localAudioTrack
      )
    );
  }, [props.videoToken]);

  useEffect(() => {
    if (room) {
      room.on("participantConnected", (participant: RemoteParticipant) =>
        dispatch(participantConnected(participant))
      );

      room.on("dominantSpeakerChanged", (participant: RemoteParticipant) => {
        dispatch(setDominantSpeaker(participant));
      });

      // room.on("trackDisabled", participantConnected);

      room.on("participantDisconnected", (participant: RemoteParticipant) =>
        dispatch(participantDisconnected(participant))
      );

      room.participants.forEach((participant: RemoteParticipant) => {
        console.log("whats up");
        dispatch(participantConnected(participant));
      });

      window.addEventListener("beforeunload", () => {
        room.disconnect();
      });
    }
  }, [room]);

  return (
    <div className="call-main d-flex">
      <Modal isOpen={isOpen} style={customStyles} contentLabel="Invite">
        <h2 className="modal-title">
          Are you sure you want to leave the call?
        </h2>
        <div className="button-container">
          <button
            className="decline-button"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Go Back
          </button>
          <button
            className="accept-button"
            onClick={() => {
              setIsOpen(false);
              room.disconnect();
              socket.close();
              history.push("/");
            }}
          >
            Accept
          </button>
        </div>
      </Modal>
      <div className="call-left gradient-bg">
        <div className="call-img inthis-call-img">
          {dominantSpeaker ? (
            <DominantSpeaker participant={dominantSpeaker} isLocal={false} />
          ) : (
            ""
          )}
        </div>
        <div className="call-options-main">
          <div className="volume-icon"></div>
          <ul>
            <li>
              {" "}
              <a
                href="#"
                title=""
                className="end-call call-option-img d-none d-md-block"
                onClick={(e) => {
                  e.preventDefault();

                  setIsOpen(true);
                }}
              ></a>
            </li>
          </ul>
          <div className="caller-main d-none d-md-block">
            {/* <img src="images/version4-images/caller.svg" alt="" /> */}
          </div>
          <div className="call-settings d-none d-md-block">
            {openSettings ? <VideoSettings /> : ""}
            <a
              href="#"
              title=""
              className="setting"
              style={{ float: "right" }}
              onClick={(e) => {
                dispatch(setOpenSettings(!openSettings));
              }}
            ></a>
            <div className="setting-info">Joining call with audio</div>
          </div>
        </div>
      </div>
      <VideoCallRight setIsOpen={setIsOpen} />
    </div>
  );
};
