import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./assets/css/bootstrap.css";
import "./assets/css/custom.css";
import "./assets/css/version2-css/custom.css";
import "./assets/css/version2-css/custom.css";
import "./assets/css/version3-css/custom.css";
import "./assets/css/version4-css/custom.css";
import "./assets/css/responsive.css";
import "./assets/css/version2-css/responsive.css";
import "./assets/css/version3-css/responsive.css";
import "./assets/css/version4-css/responsive.css";
import { Home } from "./pages/Home";
import { Lobby } from "./pages/Lobby";
import { WaitingRoom } from "./pages/WaitingRoom";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/"
          exact
          render={(props) => <Home {...props} noOffice={true} />}
        />
        <Route
          path="/:officeSlug"
          exact
          render={(props) => <Home {...props} noOffice={false} />}
        />
        <Route
          path="/:officeSlug/waiting-room"
          exact
          render={(props) => <WaitingRoom {...props} />}
        />
        <Route
          path="/:officeSlug/lobby"
          exact
          component={(props: any) => <Lobby {...props} />}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
