import React from "react";
import Header from "../components/Header";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { EnablePermissions } from "./Home/EnablePermissions";
import { HomeRegister } from "./Home/HomeRegister";
import { VideoSettingsPanel } from "../components/VideoSettings/VideoSettingsPanel";

export const Home = (props: any) => {
  const hasEnabledPermissions = useSelector(
    (state: RootStateOrAny) => state.mainReducer.hasEnabledPermissions
  );
  return (
    <React.Fragment>
      <Header />
      <section className="main-bg-sectoin meet-form-main d-flex align-items-center">
        {!hasEnabledPermissions ? (
          <EnablePermissions noOffice={props.noOffice} />
        ) : (
          <HomeRegister {...props} />
        )}
      </section>

      {hasEnabledPermissions ? <VideoSettingsPanel /> : ""}
    </React.Fragment>
  );
};
