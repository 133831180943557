import React from "react";
import Logo from "../assets/images/version2-images/logo.png";

function Header() {
  return (
    <div className="menu_wrapper">
      <div className="container d-flex justify-content-between">
        <div className="logo-wrapper">
          <a className="navbar-brand" href="#">
            <img src={Logo} alt="logo" />
          </a>
        </div>
        {/* <div className="drop-down-wrpper">
          <div className="drop-down">
            <div className="selected">
              <a href="#">
                <span>ENG</span>
              </a>
            </div>
            <div className="options">
              <ul>
                <li>
                  <a href="#">
                    FR<span className="value">1</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    ES<span className="value">2</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    NL<span className="value">3</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    DE<span className="value">3</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="last">
                    IT<span className="value">3</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Header;
