import React, { useEffect } from "react";
import Header from "../components/Header";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { EnablePermissions } from "./Home/EnablePermissions";
import { HomeRegister } from "./Home/HomeRegister";
import { VideoSettingsPanel } from "../components/VideoSettings/VideoSettingsPanel";
import { getVideoToken } from "../actions/videoCallActions";
import { VideoCall } from "../components/VideoCall/VideoCall";

export const Lobby = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userDetails = useSelector(
    (state: RootStateOrAny) => state.socketConnection.userDetails
  );

  const videoToken = useSelector(
    (state: RootStateOrAny) => state.videoCallReducer.videoToken
  );
  const videoRoomName = useSelector(
    (state: RootStateOrAny) => state.socketConnection.videoRoomName
  );
  const socket = useSelector(
    (state: RootStateOrAny) => state.socketConnection.socket
  );

  useEffect(() => {
    if (userDetails) {
      dispatch(getVideoToken(userDetails.email, userDetails.name, videoRoomName));
    }
  }, []);

  useEffect(() => {
    // Check to see if this is a page refresh
    if (!socket) {
      // alert(props.history.location.pathname.substr(1));
      history.push(`/${props.history.location.pathname.split("/")[1]}`);
    }
  }, []);

  return (
    <React.Fragment>
      {!isMobile ? <Header /> : ""}
      {videoToken ? <VideoCall videoToken={videoToken} /> : ""}
    </React.Fragment>
  );
};
