import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { InCallChatMessage } from "./InCallChatMessage";
// @ts-ignore
import ScrollToBottom from "react-scroll-to-bottom";
import { getWaitingRoomThread } from "../../actions/meetingActions";

export const InCallChat = (props: any) => {
  const textMessage = useRef<any>(null);
  const dispatch = useDispatch();

  const userPk = useSelector(
    (state: RootStateOrAny) => state.socketConnection.userDetails.pk
  );
  const officePk = useSelector(
    (state: RootStateOrAny) => state.mainReducer.officePk
  );
  const thread = useSelector(
    (state: RootStateOrAny) => state.mainReducer.thread
  );
  const officeSlug = useSelector(
    (state: RootStateOrAny) => state.mainReducer.officeSlug
  );
  const userFullName = useSelector(
    (state: RootStateOrAny) => state.socketConnection.userDetails.name
  );
  const socket = useSelector(
    (state: RootStateOrAny) => state.socketConnection.socket
  );

  function listenMessage(e: any) {
    const response_data = JSON.parse(e.data);
    if (response_data.type === "patient_ping_chat") {
      dispatch(getWaitingRoomThread(userPk, officePk));
    } else {
      return;
    }
  }
  // const participantEmail = props.user.identity.split("$")[0];

  useEffect(() => {
    if (socket) {
      // Create event listener for chat messages
      socket.addEventListener("message", listenMessage);
      return () => {
        socket.removeEventListener("message", listenMessage);
      };
    }
  }, [socket]);

  // On open set unread message to false
  useEffect(() => {
    if (socket) {
      dispatch(getWaitingRoomThread(userPk, officePk));
    }
  }, []);

  return (
    <div role="tabpanel" className="tab-pane fade active show" id="chat">
      <ScrollToBottom className="scrolling">
        {thread
          ? thread.waiting_room_thread_messages.map((message: any) => (
              <InCallChatMessage key={message.pk} message={message} />
            ))
          : ""}
      </ScrollToBottom>
      <div className="type-msg">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            socket.send(
              JSON.stringify({
                type: "GUEST_ADD_MESSAGE_TO_WAITING_ROOM_THREAD",
                message: textMessage.current.value,
                userId: userFullName,
                threadId: thread.pk,
                office: officeSlug,
              })
            );
            textMessage.current.value = "";
          }}
        >
          <input
            type="text"
            placeholder="Type your message..."
            className="common-input chat-input"
            ref={textMessage}
          />
          <input type="submit" className="primary-btn" value="Send" />
        </form>
      </div>
    </div>
  );
};
