import React from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { enableVideoAndAudioPermissions } from "../../actions/meetingActions";
import Video from "twilio-video";

type EnablePermissionsProps = {
  noOffice: boolean;
};

export const EnablePermissions = ({ noOffice }: EnablePermissionsProps) => {
  const dispatch = useDispatch();

  return (
    <div className="meet-form white-box">
      <h3>Welcome to Corigan</h3>
      {noOffice ? (
        <p>
          Corigan is your way to connect with your primary care provider. If you
          are seeing this message, than you need to enter a unique link for your
          doctor.
        </p>
      ) : Video.isSupported ? (
        <div>
          <p>
            Let's set you up to have a successful meeting. To start, please
            enable permissions.{" "}
          </p>
          <button
            className="primary-btn"
            value="Enable Permissions"
            onClick={(e) => {
              dispatch(enableVideoAndAudioPermissions());
            }}
          >
            Enable Permissions
          </button>
        </div>
      ) : (
        <p>
          Your current browser doesn't support Web Video. If you are on iPhone,
          please switch over to the Safari app. If on Android, please switch to
          Chrome or Firefox.
        </p>
      )}
    </div>
  );
};
